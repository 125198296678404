/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Box, Heading } from 'theme-ui';

const SectionHeading = ({ title, ...props }) => {
  return (
    <Box sx={styles.heading} {...props}>
      <Heading as="h3" sx={styles.title}>{title}</Heading>
    </Box>
  );
};

export default SectionHeading;

const styles = {
  heading: {
    textAlign: 'center',
    maxWidth: 660,
    margin: '0 auto 1.3em',
  },
  title: {
    color: 'heading',
    fontWeight: 600,
    fontSize: [4, null, null, 6],
    lineHeight: [1.33, 1.33, 1.5],
    letterSpacing: [null, null, null, 'heading'],
  },
};
